.section-2 {
    .container {
        display: flex;
        //max-height: 100vh;

        .sub-container-1 {
            width: 55%;
            min-height: 100vh;
            display: flex;
            background-color: #FFF;
            flex-direction: column;
            align-items: flex-start;
            padding-left: 26px;
            min-height: 100%;
            padding-bottom: 3rem;
    
            .text-box {
                border: 0.2rem solid #009440;
                margin-top: 4.5rem;    
                &-title {
                    border-radius: 90px;
                    padding: 0.4rem 2.6rem;
                    color: #009440;
                    font-size: 2.2rem;
                    line-height: 2.708rem;
                    font-weight: 700;
                    letter-spacing: 0.06em;
                }
            }
    
            .story-text {
                width: 39.5rem;
                margin-top: 2.4rem;
                font-size: 1.9rem;
                border-radius: 21px;
                line-height: 2.356rem;
                padding: 2.6rem;
            }
    
            .stats-grid {
                .stats-icons {
                    display: flex;
                    flex-wrap: wrap;
                    gap: 2.5rem 1.5rem;
                    padding-right: 4rem;
                    margin-bottom: 4rem;

    
                    .stats-icon-item {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        text-align: center;
                        flex: 0 0 calc(33.3333% - 1.5rem);
    
                        &:nth-child(1) {
                            align-self: center;
                        }
    
                        &:nth-child(3) {
                            align-self: flex-end;
                        }
                        
                        .stats-icon1 {
                            height: 3.9rem;
                            width: auto;
                        }
                        .stats-icon2 {
                            height: 5.37rem;
                            width: auto;
                        }
                        .stats-icon3 {
                            height: 5.4rem;
                            width: auto;
                        }
                        .stats-icon4 {
                            height: 5.4rem;
                            width: auto;
                        }
                        .stats-icon5 {
                            height: 5.4rem;
                            width: auto;
                        }
                        .stats-icon6 {
                            height: 4.23rem;
                            width: auto;
                        }
                        span {
                            font-size: 1.9rem;
                            margin-top: 1rem;
                        }
                    }
                }
            }
        }
    
        .sub-container-2 {
            width: 45%;
            margin-left: auto;
            background-image: url('../../public/images/section2/section2_image.png');
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
        }
    }
}

.highlight {
    color: #009440;
    font-weight: 700;
}


@media (max-width: 1000px) {
    .section-2 {
        .container {
            flex-direction: column;
            .sub-container-1 {
                display: flex;
                background-color: #F5F5F5;
                align-items: center;
                padding: 0;
                width: 100%;
                .story-text {
                    width: 77rem;
                    max-width: 80%;
                }
                .stats-grid {
                    margin-top: 3rem;
                    margin-bottom: 4rem;
                    display: flex;
                    .stats-icons {
                        padding: 0;
                        justify-content: center;
                    }
                }
            }
            .sub-container-2 {
                width: 100%;
                height: 350px;
            }
        }
    }
}


@media (max-width: 600px) {
    .section-2 {
        .container {
            .sub-container-2 {
                background-image: url('../../public/images/section2/section2_image_small.png');
            }
        }
    }
}

.section-3 {
    .container-1 {
        display: flex;
        flex-direction: column;
        background-color: #FFF;

        padding-left: 26px;
        padding-right: 26px;
        .title-block {
            margin-top: 7.7rem;
            .title {
                border-radius: 90px;
                padding: 0.4rem 26px ;
                color: #00B5ED;
                font-size: 2.2rem;
                line-height: 2.708rem;
                font-weight: 700;
                letter-spacing: 0.06em;
                border: 0.2rem solid #00B5ED;
            }
        }
        .text-block {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 1.5rem;
            font-size: 1.9rem;
            margin-top: 3.2rem;

            .text-box {
                width: 31.7rem;
                text-align: center;
            }
        }
    }
    .container-2 {
        padding-left: 26px;
        padding-right: 26px;
        padding-top: 2rem;
        padding-bottom: 4.5rem;


        background-color: #FFF;

        .gallery {
            display: flex;
            align-items: center;
            justify-content: space-between;
            img {
                width: 31.7rem;
                border: 0.2rem solid #00B5ED;
                border-radius: 21px;
            }

        }
        .title-block {
            display: flex;
            justify-content: center;
            margin-top: 5rem;
            .title {
                padding: 0.4rem 2.6rem ;
                color: #00B5ED;
                border-radius: 90px;
                font-size: 2.2rem;
                line-height: 2.708rem;
                font-weight: 700;
                letter-spacing: 0.06em;
                border: 0.2rem solid #00B5ED;
            }
            .modal-button {
                cursor: pointer;
                padding: 0.4rem 2.6rem ;
                background-color: #FFF;
                color: #00B5ED;
                border-radius: 90px;
                font-size: 2.2rem;
                font-weight: 700;
                line-height: 2.708rem;
                letter-spacing: 0.06em;
                img {
                    width: 1.4rem;
                }
            }
        }
        .device-information {
            display: flex;
            margin-top: 4.0rem;
            img {
                width: 6.8rem;
            }
            span {
                margin-left: 4.4rem;
                font-size: 1.9rem;
            }
        }
    }
    .container-3 {
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: relative;
        margin-bottom: 3rem;

        .title-block {
            margin-top: 7rem;
            margin-bottom: 3rem;
            .title {
                border-radius: 90px;
                padding: 0.4rem 26px ;
                color: #00B5ED;
                font-size: 2.2rem;
                line-height: 2.708rem;
                font-weight: 700;
                letter-spacing: 0.06em;
                border: 0.2rem solid #00B5ED;
            }
        }

        .swiper-container {
            width: 85%;
            max-width: 68.6rem;
            overflow: hidden;
            margin-bottom: 7rem;
        
            .swiper-slide {
                display: flex;
                flex-direction: column;
                height: unset;
                justify-content: space-between;
                align-items: center;
        
                span {
                    width: 80%;
                    margin-bottom: 3rem;
                    font-size: 1.9rem;
                    text-align: center;
                }
        
                img {
                    width: calc(100% - 3rem); 
                    height: 30.3rem;
                    border: 2px solid #00B5ED;
                    border-radius: 22px;
                }
            }

            .swiper-pagination {
                position: absolute;
                left: 0;
                bottom: 1rem;
                width: 100%;
                text-align: center;
            }
        
        }
        
    }
}


@media (max-width: 1130px) {
    .container-1 {
        display: none !important;
    }
    .container-2 {
        display: none !important;
    }
    .container-3 {
        display: flex !important;
    }
}

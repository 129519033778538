.section-1 {
    overflow: hidden;
    height: 100vh;
    .swiper-container {
        width: 100%;
        height: 100%;
        .swiper-wrapper {
            width: 100%;
            height: 100%;
        }
        .swiper-slide {
            background-size: cover;
            background-position:center;
            display: grid;
            place-items: end center;
            height: 100%;
    
            .image-container {
                display: grid;
                width: 100%;
                transform: translateY(-10%);
                img {
                    grid-area: 1 / 1;
                    max-height: 80%;
                    width: auto;
                    justify-self: center;
                    align-self: center;
                }
                .foreground-image {
                    max-height: 85%;
                    width: auto;
                    animation: rotateImage 20s linear infinite;
                    z-index: 2;
                }
                .background-image {
                    max-height: 55%;
                    width: auto;
                    opacity: 0.5;
                    z-index: 1;
                }
            }
        }

        .swiper-button-prev, .swiper-button-next {
            width: 3.9rem;
            height: 2.8rem;
            margin-left: 2rem;
            margin-right: 2rem;
            background-image: none !important;
            &:after {
                display: none;
            }
            img {
                width: 100%;
                height: auto;
                transition: transform 0.3s ease;
        
                &:hover {
                    transform: scale(1.2);
                }
            }
            
        }
        .swiper-pagination {
            .swiper-pagination-bullet {
                background-color: white;
                opacity: 1;
            }
        
            .swiper-pagination-bullet-active {
                background-color: #007AFF;
            }
        }
    }
}

@keyframes rotateImage {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
}

@media (max-width: 925px) {
    .section-1 {
        margin-top: 80px;
        .swiper-slide {
            .image-container {
                height: 100%;
                transform: translateY(0%) !important;

                .foreground-image {
                    max-width: unset  !important;
                    max-height: unset  !important;
                    width: 90%  !important;
                }
                .background-image {
                    max-width: unset !important;
                    max-height: unset  !important;
                    width: 60%  !important;
                }
            }
        }
    }
}

@media (max-width: 600px) {
    .section-1 {
        height: 400px;
        
        .swiper-button-prev, .swiper-button-next {
            top: 290px;
        }
        .swiper-pagination {
            top: 450px !important;
        }
    }
}
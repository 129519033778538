.navbar {
    position: fixed;
    left: 0;
    top: 0;
    display: flex;
    width: 100%;
    height: 14rem;
    justify-content: space-between;
    align-items: flex-end;
    background-color: #F5F5F5;
    color: #009440;
    font-weight: 700;
    font-size: 2rem;
    line-height: 2.48rem;
    letter-spacing: 0.06em;
    white-space: nowrap;
    z-index: 101;
    
      
    &.use-transition {
        transition: transform 0.3s ease-in-out;
    }
  
    &.hidden {
        transform: translateY(-100%);
    }
  
    .navbar-section {
        display: flex;
        flex: 1;
    
        &.left {
            margin-left: 7rem;
            margin-bottom: 2.6rem;
            justify-content: start;
        }
    
        &.center {
            margin-bottom: 2rem;
            justify-content: center;
        }
    
        &.right {
            margin-right: 7rem;
            margin-bottom: 2.6rem;
            justify-content: end;
        }
    
        .tab {
            display: flex;
            align-items: end;
            position: relative;
            cursor: pointer;
            margin-left: 2.6rem;
            margin-right: 2.6rem;
    
            &.draw-effect::after {
                content: '';
                position: absolute;
                width: 100%;
                transform: scaleX(0);
                height: 2px;
                bottom: -3px;
                left: -1px;
                background-color: #009440;
                transform-origin: bottom right;
                transition: transform 0.5s ease-out;
            }
  
            &.draw-effect:hover::after {
                transform: scaleX(1);
                transform-origin: bottom left;
            }
        }
        .logo-img {
            width: 17rem;
            height: auto;
        }
        .nav-icon {
            display: none;
        }
    }
}

@keyframes slideDown {
    from {
        transform: translateY(-100%);
    }
    to {
        transform: translateY(80px);
    }
}

@keyframes slideUp {
    from {
        transform: translateY(80px); 
    }
    to {
        transform: translateY(-100%);
    }
}

.dropdown-menu {
    flex-direction: column;
    position: fixed;
    display: flex;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #F5F5F5;
    padding-top: 3rem;
    z-index: 100;
    transform: translateY(-100%);

    .tab-dropdown {
        cursor: pointer;
        margin-top: 40px;
        margin-left: 30px;
        color: #009440;
        font-weight: 700;
        font-size: 20px;
        line-height: 2.48rem;
        letter-spacing: 0.06em;
        align-self: flex-start;
        position: relative;

        &.draw-effect::after {
            content: '';
            position: absolute;
            width: 100%;
            transform: scaleX(0);
            height: 2px;
            bottom: -3px;
            left: -1px;
            background-color: #009440;
            transform-origin: bottom right;
            transition: transform 0.5s ease-out;
        }
        &.draw-effect:hover::after {
            transform: scaleX(1);
            transform-origin: bottom left;
        }

    }
}

.rotate {
    transform: rotate(90deg);
    transition: transform 0.3s ease;
}

@media (max-width: 925px) {
    .navbar {
        height: 80px;
        .navbar-section {
            margin-bottom: unset !important;
            align-self: center;
            &.left {
                margin-left: 20px;
            }
            &.right {
                margin-right: 20px;
            }
            .tab {
                display: none;
            }
            .logo-img {
                width: 110px;
            }
            .nav-icon {
                display: block;
                cursor: pointer;
                background-color: transparent;
                border: none;
                transition: 0.3s;
                &:hover {
                    transform: scale(1.2);
                }
                svg{
                    width: 40px;
                    path {
                        color: #009440;
                    }
                }
            }
        }
    }
}

@media (min-width: 925px) {
    body {
        overflow: visible !important;
    }
}


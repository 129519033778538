.modal {
    display: none;
    position: fixed;
    z-index: 1000;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    overflow-y: scroll;
    align-items: center;
    justify-content: center;

    #monFormulaire {
        display: flex;
        flex-direction: column;
    }

    &-content {
        border: solid 3px #00B5ED;
        border-radius: 21px;
        background-color: #fefefe;
        width: 429px;
        max-width: 80%;
        position: relative;
        padding-top: 21px;
        padding-left: 26px;
        padding-right: 26px;

        animation: popup 0.2s ease-out forwards, opacity 0.2s ease-out forwards;
        @keyframes popup {
            from { transform: scale(25%); }
            to   { transform: scale(100%); }
        }
        
        @keyframes opacity {
            from { opacity: 0; }
            to   { opacity: 1; }
        }

        .modal-title {
            display: flex;
            justify-content: center;
            font-size: 22px;
            line-height: 27.28px;
            font-weight: 700;
            letter-spacing: 00.6px;
            color: #00B5ED;
            text-align: center;
            margin-bottom: 45px;
        }

        .form-group {
            font-size: 16px;
            input, textarea {
                box-sizing: border-box;
                width: 100%;
                border: solid 2px #00B5ED;
                border-radius: 8px;
                padding-left: 16px;
                font-weight: 400;
                color: #000000;
                &::placeholder {
                    font-weight: 400;
                }
            }
            input {
                height: 29px;
                margin-top: 10px;
            }
        
            textarea {
                height: 72px;
                margin-top: 28px;
                padding-top: 5px;
                resize: none;
            }
        }
        
        #modal-send {
            align-self: flex-start;
            background-color: #00B5ED;
            margin-top: 28px;
            margin-bottom: 28px;
            border-radius: 90px;
            gap: 10px;
            cursor: pointer;
            font-weight: 700;
            padding: 5px 26px;
            font-size: 22px;
            letter-spacing: 0.6px;
            color: #FFF;
        }
    }
    .confirmation-content {
        border: solid 3px #00B5ED;
        border-radius: 21px;
        background-color: #fefefe;
        width: 350px;
        position: relative;
        padding: 20px;
        text-align: center;

        animation: fadeIn 0.3s ease-in forwards;
        @keyframes fadeIn {
            from { transform: scale(0.9); opacity: 0; }
            to   { transform: scale(1); opacity: 1; }
        }

        .modal-close {
            top: 15px;
            right: 20px;
            width: 15px;
            cursor: pointer;
            &:hover,
            &:focus {
                color: black;
                text-decoration: none;
            }
        }

        .confirmation-message {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            font-size: 18px;
            // color: #00B5ED;
            margin-top: 25px;

            .icon {
                font-size: 24px;
                margin-bottom: 10px;
            }
        }
    }
    &-close {
        position: absolute;
        top: 23px;
        right: 28px;
        cursor: pointer;
      
        &:hover,
        &:focus {
            color: black;
            text-decoration: none;
        }
    }
}

.input-error {
    border: solid 3px #ff6b6b !important;

    &:focus {
        border-color: #e14040 !important;
        outline: none;
    }
}

.error-message {
    color: #e57373;
    font-size: 0.8em;
    margin-top: 5px;
    margin-left: 16px;

}

.no-scroll {
    overflow: hidden;
    height: 100vh;
}
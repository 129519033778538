.section-4 {
    // height: 100vh;
    min-height: 100vh;
    .background-container {
        background-image: url('../../public/images/section4/background.png');
        background-position: center left;
        background-size: cover;
        width: 100%;
        min-height: 100vh;
        .container {
            display: flex;
            flex-direction: column;
            width: 90%;
            .title {
                margin-top: 3rem;
                margin-left: 4.2rem;
                .text-box-title {
                    border: 0.2rem solid #009440;
                    border-radius: 90px;
                    padding: 0.4rem 2.6rem;
                    color: #009440;
                    font-size: 2.2rem;
                    line-height: 2.708rem;
                    font-weight: 700;
                    letter-spacing: 0.06em;
                }
            }
            .service-grid {
                margin-top: 2.2rem;
                margin-bottom: 3rem;
                display: flex;
                flex-wrap: wrap;
                text-align: center;
                .service-icon {
                    display: flex;
                    flex: 0 0 calc(25%);
                    flex-direction: column;
                    align-items: center;
                    text-align: center;
    
                    span {
                        font-size: 1.9rem;
                        color: #009440;
                        font-weight: 700;
                        margin-top: 1rem;
                        margin-bottom: 2rem;
                    }
                    img {
                        width: 16rem;
                    }
                }
            }
        }
    }
}


@media (max-width: 1250px) {
    .section-4 {
        .background-container {
            .container {
                .title {
                    margin-top: 4.5rem;
                }
                .service-grid { 
                    .service-icon {
                        align-items: center;
                        img {
                            width: 14rem;
                        }
                        span {
                            margin-bottom: 1rem;
                        }
                    }
                }
            }
        }
    }
}


@media (max-width: 600px) {
    .section-4 {
        .background-container {
            background-image: url('../../public/images/section4/background_small.png');
            background-position: top center;

            .container {
                width: 100% !important;

                .title {
                    margin-top: 4.5rem;
                    display: flex;
                    justify-content: center;
                }
                .service-grid { 
                    .service-icon {
                        flex: 0 0 calc(50%);
                        img {
                            width: 14rem;
                        }
                        span {
                            margin-bottom: unset;
                        }
                    }
                }
            }
        }
    }
}

.section-5 {
    .container {
        display: flex;
        flex-direction: column;
        background-color: #FFF;
        .header {
            margin-top: 3rem;
            display: flex;
            align-items: center;
            justify-content: center;
            .modal-button {
                cursor: pointer;
                padding: 0.4rem 2.6rem ;
                background-color: #FFF;
                color: #00B5ED;
                border-radius: 90px;
                font-size: 2.2rem;
                font-weight: 700;
                line-height: 2.708rem;
                letter-spacing: 0.06em;
                img {
                    width: 1.4rem;
                }
            }
        }
        .main {
            margin-left: 2.6rem;
            margin-right: 2.6rem;
            margin-bottom: 3rem;
            display: flex;
            justify-content: space-between;
            .content {
                margin-top: 7.7rem;
                margin-right: 2rem;
                .title-block {
                    .title {
                        border-radius: 90px;
                        padding: 0.4rem 2.6rem ;
                        color: #00B5ED;
                        font-size: 2.2rem;
                        line-height: 2.708rem;
                        font-weight: 700;
                        letter-spacing: 0.06em;
                        border: 0.2rem solid #00B5ED;
                    }
                }
                .text-block {
                    max-width: 91.5rem;
                    margin-top: 3.5rem;
                    font-size: 1.9rem;
                }
            }
            .image-container {
                display: flex;
                justify-content: center;
                align-items: center;
            
                .background-wrapper {
                    border-radius: 50%;
                    background-color: #FFF;
                    width: 45.9rem;
                    height: 45.9rem;
                    display: flex;
                    justify-content: center;
                    align-items: center;
            
                    img {
                        width: 47.9rem;
                        height: 47.9rem;
                        border-radius: 50%;
                    }
                }
            }
        }
    }
}

@media (max-width: 900px) {
    .section-5 {
        .container {
            .main {
                flex-direction: column;
                margin-left: 4rem;
                margin-right: 4rem;
                .content {
                    .text-block {
                        margin-bottom: 3.5rem;
                    }
                }
                .image-container {
                    .background-wrapper {
                        width: 35.5rem;
                        height: 35.5rem;

                
                        img {
                            width: 37.5rem;
                            height: 37.5rem;
                            border-radius: 50%;
                        }
                    }
                }
    
            }
        }
    }
}


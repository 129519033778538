html {
    font-size: 10px;
}
body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Cabinet Grotesk';
}

input, textarea {
    font-family: 'Cabinet Grotesk';
}

.button-effect {
    border: 0.2rem solid #cdcdcd;
    transition: box-shadow 0.3s ease;
    &:hover {
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3);
    }
}


@import 'navigation';
@import 'section1';
@import 'section2';
@import 'section3';
@import 'section4';
@import 'section5';
@import 'section6';


@media (max-width: 1512px) {
    html {
        font-size: 8.5px;
    }
}

@media (max-width: 600px) {
    html {
        font-size: 5.2px;
    }
}


@import 'modal';
.section-6 {
    background-color: #FFF;
    width: 100%;
    display: flex;
    .container {
        margin-top: 5rem;
        display: flex;
        margin-left: 2.6rem;
        margin-bottom: 10rem;
        .title-block {
            margin-top: 8rem;
            margin-bottom: 4rem;
            .title {
                border-radius: 90px;
                padding: 0.4rem 2.6rem ;
                color: #00B5ED;
                font-size: 2.2rem;
                line-height: 2.708rem;
                font-weight: 700;
                letter-spacing: 0.06em;
                border: 0.2rem solid #00B5ED;
            }
        }
        .sub-container-1 {
            display: flex;
            flex-direction: column;
            .form {
                display: flex;
                flex-direction: column;
                width: 66.8rem;
                max-width: 90%;
                .form-group {
                    margin-top: 0.3rem;
                    font-size: 1.9rem;
                    input, textarea {
                        background-color: #FFF;
                        box-sizing: border-box;
                        width: 100%;
                        border: solid 0.2rem #00B5ED;
                        border-radius: 8px;
                        padding-top: 0.5rem;
                        padding-bottom: 0.5rem;
                        padding-left: 2rem;
                        font-weight: 400;
                        color: #000000;
                        &::placeholder {
                            font-weight: 400;
                        }
                    }
                    input {
                        margin-top: 1rem;
                    }
                
                    textarea {
                        border-radius: 21px;
                        height: 21.1rem;
                        margin-top: 2.8rem;
                        padding-top: 1.5rem;
                        resize: none;
                    }
                }
                #form-send {
                    align-self: flex-start;
                    background-color: #00B5ED;
                    margin-top: 2.8rem;
                    margin-bottom: 2.8rem;
                    border-radius: 9.0rem;
                    gap: 1.0rem;
                    cursor: pointer;
                    font-weight: 700;
                    padding: 0.5rem 2.6rem;
                    font-size: 2.2rem;
                    letter-spacing: 0.06rem;
                    color: #FFF;
                    img {
                        width: 1.4rem;
                    }
                }
            }
        }
        .sub-container-2 {
            display: flex;
            flex-direction: column;
            align-items: start;
            margin-left: 17rem;

            .content-block {
                display: flex;
                flex-direction: column;
                .text-block {
                    font-size: 1.9rem;
                }
                .image-block {
                    margin-top: 5rem;
                    img {
                        width: 54.6rem;
                    }
                }
                .top-button {
                    cursor: pointer;
                    margin-top: 2.5rem;
                    align-self: flex-end;
                    width: 8.7rem;
                    height: 8.7rem; 
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    border-radius: 50%;
                    background-color: #FFF;
                    color: #009440;
                    border: solid 0.2rem #009440;
                
                    .arrow {
                        margin-bottom: 0.5rem;
                        width: 2.8rem;
                    }
                    span {
                        margin-left: 0.4rem;
                        font-size: 2.2rem;
                        font-weight: 700;
                        letter-spacing: 0.2rem;
                        line-height: 2.27rem;
                    }
                }
            }
        }
    }
}


@media (max-width: 1200px) {
    .section-6 {
        .container {
            flex-direction: column;
            .sub-container-2 {
                margin-left: unset;
            }
        }
    }
}

@media (max-width: 600px) {
    .section-6 {
        .container {
            flex-direction: column;

            .sub-container-2 {
                margin-left: unset;
                .content-block {
                    display: flex;
                    flex-direction: column;
                    .text-block {
                        font-size: 1.9rem;
                    }
                    .image-block {
                        margin-top: 5rem;
                        img {
                            max-width: 90%;
                            width: 70rem;
                        }
                    }
                    .top-button {
                        margin-right: 30px;
                    }
                }
            }
        }
    }
}




